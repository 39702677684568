import React from "react";
import { graphql, Link } from "gatsby";
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment';
import Layout from '../components/Layout'
import "../stylesheets/news.css";

const NewsFormTemplate = ({ data, pageContext }) => {

const { newsData } = data;
const newsItem = newsData.frontmatter;
console.log(newsItem)
  return (
    <Layout pageContext={pageContext}>
      <div className="container mx-auto my-6">
        {/* Arrow to navigate back */}
        <div className="px-6 bg-gray md:px-12">
          <span className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="overflow-hidden w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path strokeLinecap="round" strokeLinejoin="" oin="round" d="M15 19l-7-7 7-7" />
            </svg>
            <Link to="/actualites" className="text-lg font-semibold uppercase" style={{'textDecoration': 'none'}} >
              Retour aux actualités
            </Link>
          </span>

          {/* Title */}
          <h3 className="mt-8 w-full font-bold tracking-wide uppercase xl:w-1/2">{newsItem.titre}</h3>

          {/* Image with Content */}
          <div className="flex flex-col mt-8 space-y-8 lg:space-y-0 lg:space-x-8 lg:flex-row xl:space-x-20">
            <img
              src={newsItem.photo}
              className="object-cover lg:w-1/2 xl:w-2/5 aspect-video lg:aspect-square"
              alt=""
            />
            <div className="flex flex-col space-y-6 font-normal text-black lg:space-y-0 lg:flex-row lg:space-x-8">
              <span>
                <Moment format="DD_MM_YYYY">
                  {newsItem.datePublication}
                </Moment>
              </span>
              <div className="text-lg font-normal text-black prose">
             { newsItem.description && <ReactMarkdown children={newsItem.description.replace(/\\/gi, '  ')} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsFormTemplate;

export const newsItemQuery = graphql`
   query news($id: String!) {
    newsData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        titre
        url
        position
        photo
        datePublication
        description      
      }
    }
  }
`

