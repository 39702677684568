import React, { Component }  from 'react'

export class TeamTemplate02 extends Component {
  render() {
    const { item, index } = this.props;

    var employe =  item.frontmatter;
    var imgSegment = employe.photo.split('/');
    var imgFile = imgSegment.pop();
    var imgFolder = imgSegment.pop();
    var imgRoot = 'https://res.cloudinary.com/groupea/image/upload/'
    //var imgPath =  employe.photo.replace('/' + imgFile,'');
    const transform = 'g_north,c_pad,h_650,';
    const small = `${imgRoot}${transform}w_300/${imgFolder}/${imgFile}`;
    const medium = `${imgRoot}${transform}w_512/${imgFolder}/${imgFile}`;
    const large = `${imgRoot}${transform}w_768/${imgFolder}/${imgFile}`;
    
    return (
             <div className="col-12 col-xl-6 mt-5 pb-5" >
                 <div className="d-flex flex-wrap">
                   <div className="associe-img-container">
                 <img src={small} srcSet={`${small} 768w, ${medium} 1024w, ${large} 1280w`} alt={employe.nom}
                 className="associe-img"
                ></img>
                 </div>
                {/**/}
                     <div className="w-100 py-2" style={{paddingLeft: '45%'}}>
                     <h4 >{employe.nom}&nbsp;<b style={{ color: '#c80000' }}>/</b></h4>
                     <h5>{employe.title}</h5>                          
                     </div>
                 </div>
                
                 <div className={"d-flex flex-wrap py-3" + ((index % 3 === 0) ? " xl-associe-bg" : "") + ((index % 2 === 0) ? " md-associe-bg" : "") } >
                 <div className="w-45" ></div>
                 <div className="w-55 align-right associe-description" >
                       {employe.description}
                 </div>
             </div>
                                  
             </div>
    )
}
}