import React from 'react'
import { Link } from "gatsby";
import Moment from 'react-moment';

export default function Card({image, date, description, bgColor, textColor, href}) {
    return (
        <Link to={href} style={{textDecoration: 'none'}} className="relative">
            {/* Vertical date */}
            <div
                className="absolute top-0 left-0 text-xs font-medium text-gray-600">
                <span className="block -ml-6 transform -rotate-90 translate-y-6">
                <Moment format="DD_MM_YYYY">
                  {date}
                </Moment>
                    </span>
            </div>

            {/* Card with image and description */}
            <div className="ml-6">
                <img className="object-cover w-full h-full aspect-video" src={image} alt="placeholder"/>
                <div
                    className={`px-6 py-4`} style={{backgroundColor: bgColor, color: textColor, minWidth: '100px'}}>
                    <p className={`text-sm font-semibold uppercase line-clamp-3 leading-relaxed tracking-widest `}>
                        {description}
                    </p>
                </div>
            </div>
        </Link>
    )
}
