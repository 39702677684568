import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'react-cloudinary-lazy-image'
import { v4 } from 'uuid'

        
const setClassActive = (link) => {
  const currentUrl = typeof window !== 'undefined' ? window.location.href.toLowerCase() : '';
  const linkUrl = link.frontmatter.url.replace('/','').toLowerCase();
  return currentUrl.includes(linkUrl) ? 'active' : null
}

class Footer extends React.Component {

  render() {
    const { data } = this.props

    const { edges: siteLink } = data.allMarkdownRemark
    const { logo_footer, site_title } = data.site.siteMetadata

    return (
      <footer className="footer container my-5">
      
          <div className="float-xl-left">
                  <ul className="menu-list" style={{listStyleType:'none', padding: 0}}>
                  {siteLink &&
                siteLink.map(({ node: link }) => (
                  <li key={v4()} className="d-md-inline-block footer-item" >
                    <Link className={setClassActive(link)} to={"/" + link.frontmatter.url}>{link.frontmatter.url_title}</Link>
                  </li>
                ))
              }
                  </ul>
              </div>
              <div className="float-xl-right logo-footer">
              <div className="col-12 p-0" >
        <Img
                  cloudName={'groupea'}
                  imageName={logo_footer.split('/').pop()}
                  fluid={{
                    maxWidth: 300
                  }}
                  style={{maxWidth: 300}}
                  alt={site_title}
                  quality="best"
                />
                  </div>
                <div className="adresse-footer">
                <span>819 avenue Moreau, Québec, QC, G1V 3B5</span><br/>
                <span>T 418.653.8341  F 418.653.1989</span><br/>
                <span>info@groupea.qc.ca</span><br/><br/> 
                </div>
        
        </div>
         </footer>
    )
  }
}

const FooterQuery = () => (
  <StaticQuery
    query={graphql`
    query FooterQuery {
      allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "navigation"}, visible: { eq: true}}}, sort: {fields: [frontmatter___position], order: ASC}) {
        edges {
          node {
            frontmatter {
              url
              url_title
            }
          }
        }
        }         
      site: markdownRemark(frontmatter: {collectionKey: {eq: "settings"}}) {
        siteMetadata: frontmatter {
          site_description
          site_title
          logo_footer
        }
      }  
    }     
    `}
    render={(data, count) => <Footer data={data} count={count} />}
  />
)

export default FooterQuery

