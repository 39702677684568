import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SwiperJS from '../components/SwiperJS';
import Img from 'react-cloudinary-lazy-image'
import { v4 } from 'uuid'
import ReactMarkdown from 'react-markdown'

const ProjectHomeTemplate = (section) => {
  const projectPage = section.item;

  const data = useStaticQuery(graphql`
    query FavoriteProject {
    favProjects: allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "projets"}, favorite: {eq: true}}}, sort: {fields: [frontmatter___favoritePosition], order: ASC}) {
      edges {
        node {
          frontmatter {
            url
            nom
            categorie
            favoritePosition
            photos {
                position
                photo
                slider
              }            
          }
        }
      }
    }
    categoriesQry: allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "categories"}}}, sort: {fields: [frontmatter___favoritePosition], order: ASC}) {
      edges {
        node {
          frontmatter {
            collectionKey
            url
            nom        
            position    
            favoritePosition
          }          
        }
      }
    }
}
`);

  const { favProjects, categoriesQry } = data;

  //const projects = (projectPage.favorites ? favProjects : allProjects).edges.filter(x => x.node.frontmatter.categorie !== 'temporaire' && x.node.frontmatter.photos).map(m => m.node.frontmatter)
  const projects = favProjects.edges.filter(x => x.node.frontmatter.categorie !== 'temporaire' && x.node.frontmatter.photos).map(m => m.node.frontmatter)

  const categories = categoriesQry.edges.filter(f => f.node.frontmatter.url !== 'temporaire').map(m => m.node.frontmatter)

  // const categoriesProject = categoriesProjectQry.edges.map(m => m.node.frontmatter).sort((a, b) => a.favoritePosition - b.favoritePosition)
  // categories.forEach(e => e.project = categoriesProject.find(f => f.categorie.includes(e.url)))

  //const categorieNom = "NOS PROJETS";

  if (projects) {

    let swiperFields = projects.map(m => ({
      url: m.url,
      nom: m.nom,
      photo: m.photos.filter(f => f.slider === "1").map(m => m.photo).sort((a, b) => a.position - b.position)[0],
      categorie: m.categorie && categories.find(f => f.url === m.categorie[0]) //categories.find(f => f.url === m.categorie[0]) //categories.find(f => f.url === m.project.categorie[0])
    }))
   
    return (
      <section className="grille">
        <div className="row no-gutters">
          <div className="col" id="vignette" >
          <SwiperJS swiperFields={swiperFields} naviguable={true}></SwiperJS>   

          </div>
        </div>

        {projectPage.body ?
          <div className="container">
            <div className="row">
              <div className="col pt-5 pb-5">
                <div className={'col ' + projectPage.className}>
                  {projectPage.body && <ReactMarkdown
                    source={projectPage.body.replace(/\\/gi, '  ')}
                    escapeHtml={false}
                  />
                  }
                </div>
              </div>
            </div>
          </div> : null}

        <div className="row no-gutters pb-5">
          {projects.length !== 0 &&
            projects.map(project => {
             
              const catPhoto = project?.photos?.find(f => f.slider === "1")?.photo;
              
              if (catPhoto) {
                var imgSegment = catPhoto.split('/');
                var imgFile = imgSegment.pop();
                var imgFolder = imgSegment.pop();
                return (
                  <div key={v4()} className="col-md-6 col-xl-4 p-0 category-home-vignette">
                    <a href={"/projets/" + (project.categorie && project.categorie[0]) + "/" + project.url}>
                      <div className={"d-inline-block w-100 category-home-header " + project.url}>
                        <h3 className="mx-2 mt-3">
                          <b style={{ color: '#c80000', paddingLeft: '10px' }}>\</b> <span className={"categorie-nom-label " + project.categorie}>{project.nom}</span>
                        </h3>
                      </div>
                      <div className="category-home-img h-100">
                      <Img
                        cloudName={'groupea'}
                        imageName={'projets/' + imgFolder + '/' + imgFile}
                        fluid={{
                          maxWidth: 625
                        }}
                        alt={imgFile}
                        quality={false}
                      /></div>
                      </a>
                  </div>
                )
              } else {
                return null
              }
            })
          }
        </div>
      </section>
    )
  } else {
    return (<div key={v4()}>Aucun projet</div>)
  }


}

export default ProjectHomeTemplate